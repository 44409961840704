import { MatConfirmDownloadAlertComponent } from './mat-confirm-dl-alert.component';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class DownloadAlertService {

  constructor(private dialog: MatDialog) { }


  /**
   *
   * open confirm.
   * @param {*} msg Prompt box message.
   * @returns
   * @memberof DownloadAlertService
   */
  openConfirmDialog(msg, defaultBtn = ['yes', 'no'], defaultTitle = '') {
    return this.dialog.open(MatConfirmDownloadAlertComponent, {
      width: '390px',
      panelClass: 'confirm-dialog-container',
      disableClose: true,
      position: {},
      data: {
        message: msg,
        btns: defaultBtn,
        title: defaultTitle
      }
    });
  }
}
