<div class="msgBox">
  <div class="popup-title">
    <div class="popup-title-content">Alert- Document Not Found</div>
    <i (click)="closeDialog()" class="popup-close-icon mdui-icon material-icons">clear</i>
  </div>
  <div class="popup-content">
      <div class="msg">
        <i class="mdui-icon material-icons warning m_r">error</i>
        <span>NOTE: This record no longer exists in MMPR, please refresh your browser/page.</span>
      </div>
  </div>
  <div class="popup-footer">
    <button class="submit_btn confirm" style="width: 82px" mat-flat-button *ngFor="let btn of data.btns" [mat-dialog-close]="btn">
      <i class="mdui-icon material-icons sub_btn">check</i>
        <span class="btn_txt">{{btn}}</span>            
    </button>
  </div>
</div>
